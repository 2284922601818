import { Injectable, inject, signal } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, map, Observable, pairwise, tap } from 'rxjs';

/** Previous urls service. */
@Injectable({ providedIn: 'root' })
export class PreviousUrlService {
	private readonly router = inject(Router);

	private readonly previousUrl = signal<string | null>(null);

	/** Initialize router observation. */
	public initialize(): Observable<void> {
		return this.router.events
			.pipe(
				filter((event): event is RoutesRecognized => event instanceof RoutesRecognized),
				pairwise(),
				tap(events => this.previousUrl.set(events[0].urlAfterRedirects)),
				map(() => undefined),
			);
	}

	/**
	 * Gets previous url visited by user.
	 * @param defaultUrl Default URL to use if there is no previous URL.
	 */
	public getPreviousUrl(defaultUrl: string): string {
		return this.previousUrl() ?? defaultUrl;
	}
}
