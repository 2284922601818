import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { EMPTY, Observable, shareReplay, switchMap, tap } from 'rxjs';

import { inject } from '@angular/core';

import { isValidationErrorDto } from '../dtos/validation-error.dto';
import { UserService } from '../services/user.service';
import { RedirectService } from '../services/redirect.service';
import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';

/** Interceptor errors and logs out user if he is blocked. */
export class BlockedUserInterceptor implements HttpInterceptor {
	private readonly userService = inject(UserService);

	private readonly redirectService = inject(RedirectService);

	private logoutUser$: Observable<void> | null = null;

	/** @inheritdoc */
	public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(req).pipe(
			catchHttpErrorResponse(error => {
				if (this.isUserBlocked(error)) {
					this.logoutUser$ ??= this.userService.logout().pipe(
						tap(() => this.redirectService.redirectToAuthPage()),
						tap(() => {
							this.logoutUser$ = null;
						}),
						shareReplay({ refCount: true, bufferSize: 1 }),
					);

					return this.logoutUser$.pipe(
						switchMap(() => EMPTY),
					);
				}

				throw error;
			}),
		);
	}

	private isUserBlocked(error: HttpErrorResponse): boolean {
		if (error.status === HttpStatusCode.Unauthorized) {
			const apiError = error.error;

			if (isValidationErrorDto(apiError) && apiError.type === 'client_error') {
				return apiError.errors[0].code === 'blocked';
			}
			return false;
		}
		return false;
	}
}
